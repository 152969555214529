<template>
  <div class="bg">
    <div class="container">
      <div class="how">
        <a href="#explain" @click.prevent="goToExplain">如何嵌入?</a>
      </div>
      <div class="section-one">
        <iframe src="https://fhgys.com/#/" frameborder="0" scrolling="Yes" width="100%" height="100%"  noresize="noresize"></iframe>
      </div>
      <div id="explain" class="explain">
        <div class="ex-title">嵌入说明：</div>
        <div class="ex-cnt">
          凤凰联盟产品插件，独家支持iframe嵌入，我们会不断丰富完善各类产品数量
        </div>
        <div>
          <div class="ex-title">嵌入代码：</div>
            <pre>
              <span class="text">{{data}}<span style="color:rgb(217, 0, 27);">{{data1}}</span>{{data2}}</span>
            </pre>
          <div class="ex-cnt">tgusername替换为您想要设定的接待 Telegram 账号</div>
          <div class="ex-cnt">Width在PC端最小限制为900px，最宽为1200px，height最小1440px，嵌入时可根据自身网站实际情况调整，H5端无需额外设置</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{   
      data: '<iframe src="https://fhgys.com/#/?header=false&tg=@',
      data1: 'tgusername',
      data2: '" width="100%" height= "100%" name="topFrame" noresize="noresize" frameborder="0" id="topFrame"></iframe>',
    }
  },
  methods: {
    goToExplain(){
      document.getElementById("explain").scrollIntoView();
    }
  }
}
</script>
<style lang="sass" scoped>
  .bg
    background-color: #fff
  .how
    padding: 20px 0
    a
      text-decoration: none
      color: rgb(202, 29, 34)
  .section-one
    position: relative
    width: 100%
    height: 0
    padding-bottom: 100%
    iframe
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      overflow: hidden
  .explain
    margin-top: 20px
    .ex-title
      margin-bottom: 10px
    .ex-cnt
      padding-bottom: 10px
  pre
    white-space: pre-wrap
    word-wrap: break-word
    background: #F2F2F2
    color: #000
    padding: 20px 20px 0 20px
    margin-bottom: 10px
  @media screen and (max-width: 520px)
    .container
     width: 95vw
</style>